import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        <div className="flex flex-wrap gap-4 justify-center items-center pb-5">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/recursos-31efd.appspot.com/o/cruz%20mansory.png?alt=media&token=1529f64d-a5d7-4a75-bfcd-cf345723344a"
            alt="Not found"
            width={"350px"}
            data-aos="zoom-in"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Anuncios%2F1713833667548_0.png?alt=media&token=5369d47d-fb06-4a5c-9fc6-de93791bb251"
            alt="Not found"
            width={"350px"}
            data-aos="zoom-in"
          />
        </div>
        <div className="flex justify-center items-center pb-5">
          {rpdata?.gmb?.link.length > 1 ? (
            <a
              href={rpdata?.gmb?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={rpdata?.gmb?.img}
                alt="Not found"
                width={"250px"}
                data-aos="zoom-in"
              />
            </a>
          ) : null}
        </div>
        {rpdata?.dbSocialMedia?.redes.length > 0 ||
        rpdata?.dbSocialMedia?.directorios.length > 0 ? (
          <h2 className="text-center pb-5">Find Us On</h2>
        ) : null}
        {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
        <div
          className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}
        >
          {rpdata?.dbSocialMedia?.directorios.length > 0
            ? rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div
                    className="text-center content-directorios"
                    key={index}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
          {rpdata?.dbSocialMedia?.redes.length > 0
            ? rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div
                    className="text-center content-directorios"
                    key={index}
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.image} alt="Not Found" width={"250px"} />
                    </a>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}

export default Directories;
